import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Socials from "./socials"

class Menu extends React.PureComponent {
  state = {
    menuOpen: false,
  }

  closeMenu = event => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.setState({ menuOpen: false })
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.closeMenu, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeMenu, false)
  }

  render() {
    return (
      <Wrapper
        onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
        open={this.props.isOpen}
      >
        <MenuList open={this.props.isOpen}>
          <Item>
            <Link
              to="/commercials"
              partiallyActive={true}
              activeStyle={{
                opacity: 0.5,
              }}
              style={{
                "& a": {
                  display: "none",
                },
              }}
            >
              Commercials
            </Link>
          </Item>
          <Item>
            <Link
              to="/photography"
              activeStyle={{
                opacity: 0.5,
              }}
              style={{
                "& a": {
                  display: "none",
                },
              }}
            >
              Photography
            </Link>
          </Item>
          <Item>
            <Link
              to="/prints"
              activeStyle={{
                opacity: 0.5,
              }}
              style={{
                "& a": {
                  display: "none",
                },
              }}
            >
              Prints For Sale
            </Link>
          </Item>
          {/* <Item>
            <Link
              to="/billboards"
              activeStyle={{
                opacity: 0.5,
              }}
              style={{
                "& a": {
                  display: "none",
                },
              }}
            >
              Billboards
            </Link>
          </Item> */}
          <Item>
            <Link
              to="/films"
              partiallyActive={true}
              activeStyle={{
                opacity: 0.5,
              }}
              style={{
                "& a": {
                  display: "none",
                },
              }}
            >
              Films
            </Link>
          </Item>

          <Item space>
            <Link
              to="/more-vibes"
              activeStyle={{
                opacity: 0.5,
              }}
              style={{
                "& a": {
                  display: "none",
                },
              }}
            >
              More Vibes
            </Link>
          </Item>
          <Item>
            <Link
              to="/about"
              activeStyle={{
                opacity: 0.5,
              }}
            >
              About
            </Link>
          </Item>
          <Item>
            <Link
              to="/services"
              activeStyle={{
                opacity: 0.5,
              }}
            >
              Services
            </Link>
          </Item>
          <Item>
            <Link
              to="/contact"
              activeStyle={{
                opacity: 0.5,
              }}
            >
              Contact
            </Link>
          </Item>
        </MenuList>
        <Socials open={this.props.isOpen} />
      </Wrapper>
    )
  }
}
export default Menu

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: ${props => (props.open ? "30vw" : 0)};
  transition: width 0.3s;
  z-index: -1;

  @media only screen and (max-width: 768px) {
    width: ${props => (props.open ? "50vw" : 0)};
  }
  @media only screen and (max-width: 600px) {
    width: ${props => (props.open ? "70vw" : 0)};
  }
`
const MenuList = styled.ul`
  margin: 50px 0 0 0;
  list-style: none;
  display: ${props => (props.open ? "block" : "none")};
  transition: opacity 0.1s;
  transition-delay: 0.1s;
`
const Item = styled.li`
  padding-left: 0;
  margin-bottom: ${props => (props.space ? "2rem" : "0.725rem")};

  a {
    font-family: "alternategothic2_btregular";
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1;
    color: white;
    text-decoration: none;
    color: white;

    :hover {
    }

    :visited {
      color: white;
    }

    @media only screen and (max-width: 768px) {
      /* font-size: 4rem; */
      /* line-height: 1.5; */
    }
    @media only screen and (max-width: 480px) {
      /* font-size: 3rem; */
      /* line-height: 1.5; */
    }
  }
`
