/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import styled from "styled-components"
import "./layout.css"

const Container = styled.div``

class Layout extends React.PureComponent {
  render() {
    return (
      <>
        <Header hideNav={this.props.hideNav} />
        <Container>{this.props.children}</Container>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
