import React from "react"
import styled from "styled-components"
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"

const SocialsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  display: ${props => (props.open ? "grid" : "none")};

  margin-right: 20px;
  /* max-width: 100px;   */
  /* margin: 20px auto; */
  @media (max-width: 1050px) {
    /* display: ${props => (props.hide ? "none" : "block")}; */
  }
`

const SocialIcon = styled.div`
  font-size: 1.2rem;
  display: inline;
  transition: 0.5s ease;
  margin: 0 7px;
  color: white;
  &:hover {
    color: #650000;
  }
`

const Socials = props => (
  <SocialsContainer open={props.open}>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.instagram.com/trinidadandtobagorocks/"
      >
        <FaInstagram />
      </a>
    </SocialIcon>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/TandTrocks/"
      >
        <FaFacebookF />
      </a>
    </SocialIcon>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.youtube.com/channel/UC7s-2mJq9Ef50xeR8HYHIAA"
      >
        <FaYoutube />
      </a>
    </SocialIcon>
  </SocialsContainer>
)

export default Socials
